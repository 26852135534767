import { useState } from "react";

import { getFirestore, collection, addDoc } from "firebase/firestore";

const items = String.raw`
2020	Leyli Mammadova and Leonid Ryvkin	On the extension problem for weak moment maps	https://arxiv.org/abs/2001.00264	FALSE	FALSE																	
2020	Nero Budur, Robin van der Veer, Lei Wu et al.	Zero loci of Bernstein-Sato ideals -- II	https://arxiv.org/abs/2001.05728	TRUE	TRUE	LIRIAS3439414	Budur, Nero, et al. “Zero Loci of Bernstein-Sato Ideals -- II.” <i>Selecta Mathematica-New Series</i>, vol. 27, no. 3, 2021, pp. Selecta Mathematica-New Series; 2021; Vol. 27; iss. 3; pp.															
2020	Karel Dekimpe, Sam Tertooy	Algorithms for twisted conjugacy classes of polycyclic-by-finite groups	https://arxiv.org/abs/2002.08285	TRUE	TRUE	LIRIAS3345808	Dekimpe, Karel, and Tertooy, Sam. “Algorithms for Twisted Conjugacy Classes of Polycyclic-by-Finite Groups.” <i>Topology And Its Applications</i>, vol. 293, 2021, pp. Topology And Its Applications; 2021; Vol. 293; pp.															
2020	Nero Budur, Tran Quang Tue	On contact loci of hyperplane arrangements	https://arxiv.org/abs/2002.08480	TRUE	TRUE	LIRIAS3520639	Budur, Nero, and Tran, Quang Tue. “On Contact Loci of Hyperplane Arrangements.” Advances In Applied Mathematics, vol. 132, 2022, pp. Advances In Applied Mathematics; 2022; Vol. 132; pp.															
2020	L. D. Molag	The local universality of Muttalib-Borodin ensembles when the parameter  $\theta$ is the reciprocal of an integer	https://arxiv.org/abs/2003.11299	FALSE	FALSE	LIRIAS2965232	Molag, Leslie Diëgo. The Local Universality of Muttalib-Borodin Ensembles. 2020.															
2020	Grzegorz Świderski and Bartosz Trojan	Asymptotic behaviour of Christoffel-Darboux Kernel via three-term recurrence relation I, Constructive Approximation.	https://arxiv.org/abs/2004.07826	TRUE	FALSE	LIRIAS3226346	Swiderski, Grzegorz, and Trojan, Bartosz. “Asymptotic Behaviour of Christoffel-Darboux Kernel Via Three-Term Recurrence Relation I.” <i>Constructive Approximation</i>, vol. 54, no. 1, 2020, pp. 49–116.															
2020	Johannes Nicaise and John Christian Ottem	A refinement of the motivic volume, and specialization of birational  types	https://arxiv.org/abs/2004.08161	FALSE	FALSE																	
2020	Tey Berendschot and Stefaan Vaes	Nonsingular Bernoulli actions of arbitrary Krieger type.	https://arxiv.org/abs/2005.06309	FALSE	FALSE																	
2020	Karel Dekimpe and Pieter Senden	The $R_\infty$-property for right-angled Artin groups	https://arxiv.org/abs/2005.14487	TRUE	TRUE	LIRIAS3441176	Dekimpe, Karel, and Senden, Pieter. “The R-Infinity-Property for Right-Angled Artin Groups.” <i>Topology And Its Applications</i>, vol. 293, 2021, pp. Topology And Its Applications; 2021; Vol. 293; pp.															
2020	Jorge Castillejos, Sam Evington, Aaron Tikuisis and Stuart White	Classifying maps into uniform tracial sequence algebras	https://arxiv.org/abs/2006.04485	TRUE	FALSE																	
2020	Amine Marrakchi and Stefaan Vaes	Nonsingular Gaussian actions: beyond the mixing case.	https://arxiv.org/abs/2006.07238	FALSE	FALSE																	
2020	Grzegorz \'Swiderski and Bartosz Trojan	About essential spectra of unbounded Jacobi matrices	https://arxiv.org/abs/2006.07959	TRUE	TRUE																	
2020	Karel Dekimpe, Daciberg Lima Gon\c{c}alves, Oscar Ocampo	The R$_\infty$ property for pure Artin braid groups	https://arxiv.org/abs/2006.08286	TRUE	TRUE	LIRIAS3345803	Dekimpe, Karel, et al. “The R∞ Property for Pure Artin Braid Groups.” <i>Monatshefte Fur Mathematik</i>, 2020, pp. Monatshefte Fur Mathematik; 2020.															
2020	Nero Budur, Robin van der Veer	Monodromy Conjecture for log generic polynomials	https://arxiv.org/abs/2007.02594	TRUE	TRUE	LIRIAS3601055	Budur, Nero, and Van der Veer, Robin. “Monodromy Conjecture for Log Generic Polynomials.” <i>Mathematische Zeitschrift</i>, 2021, pp. Mathematische Zeitschrift; 2021.															
2020	Paula Macedo Lins de Araujo and Yuri Santos Rego	Twisted conjugacy in soluble arithmetic groups.	https://arxiv.org/abs/2007.02988	FALSE	FALSE																	
2020	Juan Criado del Rey and Arno Kuijlaars	A vector equilibrium problem for symmetrically located point charges on a sphere.	https://arxiv.org/abs/2008.01017	FALSE	FALSE																	
2020	Grzegorz \'Swiderski and Bartosz Trojan	Orthogonal polynomials with periodically modulated recurrence  coefficients in the Jordan block case	https://arxiv.org/abs/2008.07296	TRUE	TRUE																	
2020	Iakovos Androulidakis and Marco Zambon	Integration of Singular Subalgebroids	https://arxiv.org/abs/2008.07976	TRUE	TRUE																	
2020	Karel Dekimpe, Iris Van den Bussche	An Averaging Formula for Nielsen numbers on Infra-Solvmanifolds	https://arxiv.org/abs/2008.09432	TRUE	TRUE	LIRIAS3524391	Van den Bussche, Iris. <i>An Averaging Formula for Nielsen Numbers on Infra-Solvmanifolds</i>. 2021.															
2020	Stephane Geudens, Marco Zambon	Deformations of Lagrangian submanifolds in log-symplectic manifolds	https://arxiv.org/abs/2009.01146	TRUE	TRUE																	
2020	Alexandru Chirvasitu and Mateusz Wasilewski	Random quantum graphs	https://arxiv.org/abs/2011.14149	FALSE	FALSE																	
2021	Marco Zambon	Holonomy transformations for Lie subalgebroids	https://arxiv.org/abs/2103.10409	FALSE	FALSE																	
2021	Nero Budur, Leonardo A. Lerer, Haopeng Wang	Absolute sets of rigid local systems	https://arxiv.org/abs/2104.00168	FALSE	FALSE																	
2021	Lei Wu	Riemann-Hilbert correspondence for Alexander complexes	https://arxiv.org/abs/2104.06941	FALSE	FALSE																	
2021	Guillem Blanco, Nero Budur, Robin van der Veer	Monodromy conjecture for semi-quasihomogeneous hypersurfaces	https://arxiv.org/abs/2106.11015	TRUE	TRUE	LIRIAS3541929	Blanco, Guillem, et al. “Monodromy Conjecture for Semi-Quasihomogeneous Hypersurfaces.” <i>Mathematische Nachrichten</i>, 2021, pp. Mathematische Nachrichten; 2021.															
2021	Daniel Drimbe and Stefaan Vaes	Superrigidity for dense subgroups of Lie groups and their actions on  homogeneous spaces	https://arxiv.org/abs/2107.06159	FALSE	FALSE																	
2021	Grzegorz \'Swiderski and Bartosz Trojan	Orthogonal polynomials with periodically modulated recurrence  coefficients in the Jordan block case II	https://arxiv.org/abs/2107.11154	FALSE	FALSE																	
2021	Matthijs Borst and Martijn Caspers and Mateusz Wasilewski	Bimodule coefficients, Riesz transforms on Coxeter groups and strong  solidity	https://arxiv.org/abs/2109.00588	FALSE	FALSE																	
2021	Stephane Geudens, Alfonso G. Tortorella, Marco Zambon	Deformations of Symplectic Foliations	https://arxiv.org/abs/2110.05298	TRUE	TRUE	LIRIAS3353178	Schaetz, Florian, and Zambon, Marco. “Gauge Equivalences for Foliations and Pre-Symplectic Structures.” <i>Communications In Contemporary Mathematics</i>, vol. 23, no. 07, 2021, pp. Communications In Contemporary Mathematics; 2021; Vol. 23; iss. 07; pp.															
2021	Martijn Caspers, Mario Klisse, Adam Skalski, Gerrit Vos, Mateusz Wasilewski	Relative Haagerup property for arbitrary von Neumann algebras	https://arxiv.org/abs/2110.15078	FALSE	FALSE																	
2010	David Bourqui, Johannes Nicaise and Julien Sebag	Arc Schemes in Geometry and Differential Algebra		TRUE	TRUE	LIRIAS62192	Nicaise, Johannes, and Sebag, Julien. “Greenberg Approximation and the Geometry of Arc Spaces.” <i>Communications in Algebra</i>, vol. 38, no. 11, 2010, pp. 4077–4096.															
2016	Antonio Vargas	The Saff-Varga width conjecture and entire functions		TRUE	TRUE	LIRIAS1927639	Vargas, Antonio. <i>Scaling Limits for Partial Sums of Power Series</i>. 2016.															
2018	Antoine Chambert-Loir, Johannes Nicaise and Julien Sebag	Motivic integration		TRUE	TRUE	LIRIAS1742895	Chambert-Loir, Antoine, et al. <i>Motivic Integration</i>. Vol. 325, Birkhaüser; Basel, 2018.															
2019	Sam Tertooy	The Reidemeister Spectra of Low Dimensional Almost-Crystallographic Groups. Experimental Mathematics.	https://arxiv.org/abs/1901.05393	TRUE	TRUE	LIRIAS2826670	Tertooy, Sam. “The Reidemeister Spectra of Low Dimensional Almost-Crystallographic Groups.” <i>Experimental Mathematics</i>, 2019, pp. Experimental Mathematics; 2019; pp.																	
2019	Michael Björklund, Zemer Kosloff and Stefaan Vaes	Ergodicity and type of nonsingular Bernoulli actions. Inventiones Mathematicae.	https://arxiv.org/abs/1901.05723	TRUE	TRUE	LIRIAS3244378	Bjorklund, Michael, et al. “Ergodicity and Type of Nonsingular Bernoulli Actions.” <i>Inventiones Mathematicae</i>, vol. 224, no. 2, 2020, pp. 573–625.																	
2019	Jorge Castillejos, Samuel Evington, Aaron Tikuisis et al.	Nuclear dimension of simple C*-algebras	https://arxiv.org/abs/1901.05853	TRUE	TRUE	LIRIAS3429746	Castillejos, Jorge, et al. “Nuclear Dimension of Simple C*-Algebras.” <i>Inventiones Mathematicae</i>, vol. 224, no. 1, 2020, pp. 245–290.																	
2019	Ethan Cotterill, Xiang He, and Naizhen Zhang	Secant planes of a general curve via degenerations	https://arxiv.org/abs/1901.06430	TRUE	TRUE	NONE	Ethan Cotterill, Xiang He and Naizhen Zhang, Secant Planes of a General Curve via&nbsp; degenerations. <em>Geometriae Dedicata</em>																	
2019	Charlotte Kirchhoff-Lukat	Lagrangian branes with boundary and symplectic methods for stable generalized complex manifolds.	https://arxiv.org/abs/1901.10438	FALSE	FALSE																			
2019	Leyli Mammadova and Marco Zambon	Lie 2-algebra moment maps in multisymplectic geometry	https://arxiv.org/abs/1901.10842	TRUE	TRUE	LIRIAS3039979	Mammadova, Leyli, and Zambon, Marco. “Lie 2-Algebra Moment Maps in Multisymplectic Geometry.” <i>Differential Geometry And Its Applications</i>, vol. 70, 2020, pp. Differential Geometry And Its Applications; 2020; Vol. 70; pp.																	
2019	Jorge Castillejos, Samuel Evington	Nuclear dimension of simple stably projectionless C*-algebras	https://arxiv.org/abs/1901.11441	TRUE	TRUE	LIRIAS3429742	Castillejos, Jorge, and Evington, Samuel. “NUCLEAR DIMENSION OF SIMPLE STABLY PROJECTIONLESS C*-ALGEBRAS.” <i>Analysis &amp; Pde</i>, vol. 13, no. 7, 2020, pp. 2205–2240.																	
2019	Nero Budur, Yongqiang Liu	Length of Perverse Sheaves on Hyperplane Arrangements	https://arxiv.org/abs/1903.01924	TRUE	TRUE	LIRIAS2842177	Budur, Nero, and Liu, Yongqiang. “LENGTH OF PERVERSE SHEAVES ON HYPERPLANE ARRANGEMENTS.” <i>European Journal of Mathematics</i>, vol. 6, no. 3, 2020, pp. 681–712.																	
2019	Alfonso Garmendia and Marco Zambon	Quotients of singular foliations and Lie 2-group actions	https://arxiv.org/abs/1904.08890	TRUE	TRUE	LIRIAS2942924	Zambon, Marco, and Garmendia, Alfonso. “Quotients of Singular Foliations and Lie 2-Group Actions.” <i>Journal Of Noncommutative Geometry</i>, 2020, pp. Journal Of Noncommutative Geometry; 2020.																	
2019	Mattias Jonsson and Johannes Nicaise	Convergence of p-adic pluricanonical measures to Lebesgue measures on  skeleta in Berkovich spaces	https://arxiv.org/abs/1905.02646	TRUE	TRUE	LIRIAS2934029	Nicaise, Johannes, and Jonsson, Mattias. “Convergence of p-Adic Pluricanonical Measures to Lebesgue Measures on Skeleta in Berkovich Spaces.” <i>Journal De L'École Polytechnique. Mathématiques</i>, vol. 7, 2020, pp. 287–336.																	
2019	Dietrich Burde, Karel Dekimpe and Bert Verbeke	Almost inner derivations of Lie algebras II. International Journal of Algebra and Computation.	https://arxiv.org/abs/1905.08145	TRUE	TRUE	LIRIAS3471995	Burde, Dietrich, et al. “Almost Inner Derivations of Lie Algebras II.” <i>International Journal of Algebra and Computation</i>, vol. 31, no. 02, 2021, pp. 341–364.																	
2019	Martijn Caspers, Yusuke Isono, Mateusz Wasilewski	$L_2$-cohomology, derivations and quantum Markov semi-groups on  $q$-Gaussian algebras	https://arxiv.org/abs/1905.11619	TRUE	TRUE	LIRIAS3522268	Caspers, Martijn, et al. “L-2-Cohomology, Derivations, and Quantum Markov Semi-Groups on q-Gaussian Algebras.” <i>International Mathematics Research Notices</i>, vol. 2021, no. 9, 2021, pp. 6405–6441.																	
2019	Ethan Cotterill, Adri\'an Alonso Gonzalo, and Naizhen Zhang	The Strong Maximal Rank Conjecture and higher rank Brill--Noether theory	https://arxiv.org/abs/1906.07618	TRUE	TRUE	NONE	Ethan Cotterill, Adrián Alonso Gonzalo and Naizhen Zhang, The Strong Maximal Rank Conjecture and higher rank Brill-Noether theory																	
2019	Mateusz Wasilewski	A simple proof of the complete metric approximation property for q-Gaussian algebras. Colloquium Mathematicum.	https://arxiv.org/abs/1907.00730	TRUE	TRUE	LIRIAS2893785	Wasilewski, Mateusz. “A Simple Proof of the Complete Metric Approximation Property for q-Gaussian Algebras.” <i>Colloquium Mathematicum</i>, vol. 163, no. 1, 2021, pp. 1–14.																	
2019	Christophe Charlier, Maurice Duits, Arno Kuijlaars and Jonatan Lenells	A periodic hexagon tiling model and non-Hermitian orthogonal polynomials	https://arxiv.org/abs/1907.02460	TRUE	TRUE	LIRIAS3444068	Charlier, Christophe, et al. “A Periodic Hexagon Tiling Model and Non-Hermitian Orthogonal Polynomials.” <i>Communications In Mathematical Physics</i>, vol. 378, no. 1, 2020, pp. 401–466.																	
2019	Nero Budur, Robin van der Veer, Lei Wu et al.	Zero loci of Bernstein-Sato ideals	https://arxiv.org/abs/1907.04010	TRUE	TRUE	LIRIAS3275914	Budur, Nero, et al. “Zero Loci of Bernstein-Sato Ideals.” <i>Inventiones Mathematicae</i>, vol. 225, no. 1, 2021, pp. 45–72.																	
2019	Alfonso G. Tortorella and Luca Vitagliano and Ori Yudilevich	Homogeneous G-structures	https://arxiv.org/abs/1907.06449	TRUE	TRUE	LIRIAS3518399	Tortorella, Alfonso Giuseppe, et al. “Homogeneous G-Structures.” <i>Annali Di Matematica Pura Ed Applicata</i>, vol. 199, no. 6, 2020, pp. 2357–2380.																	
2019	Stephane Geudens, Marco Zambon	Coisotropic submanifolds in $b$-symplectic geometry	https://arxiv.org/abs/1907.09251	TRUE	TRUE	LIRIAS3353177	Geudens, Stephane, and Zambon, Marco. “Coisotropic Submanifolds in b-Symplectic Geometry.” <i>Canadian Journal Of Mathematics-Journal Canadien De Mathematiques</i>, vol. 73, no. 3, 2021, pp. 737–768.																	
2019	Grzegorz \'Swiderski and Bartosz Trojan	Asymptotic behaviour of Christoffel-Darboux kernel via three-term  recurrence relation I	https://arxiv.org/abs/1909.09107	TRUE	TRUE	LIRIAS3226346	Swiderski, Grzegorz, and Trojan, Bartosz. “Asymptotic Behaviour of Christoffel-Darboux Kernel Via Three-Term Recurrence Relation I.” <i>Constructive Approximation</i>, vol. 54, no. 1, 2020, pp. 49–116.																	
2019	Grzegorz \'Swiderski	Spectral properties of some complex Jacobi matrices	https://arxiv.org/abs/1910.07473	TRUE	TRUE	LIRIAS3006499	Swiderski, Grzegorz. “Spectral Properties of Some Complex Jacobi Matrices.” <i>Integral Equations And Operator Theory</i>, vol. 92, no. 2, 2020, pp. Integral Equations And Operator Theory; 2020; Vol. 92; iss. 2; pp.																	
2019	Xiang He and Naizhen Zhang	Degenerations of Grassmannians via lattice configurations	https://arxiv.org/abs/1910.08163	FALSE	FALSE																			
2019	Xiang He and Naizhen Zhang	Linked Flag Schemes and Applications.		FALSE	FALSE																			
2019	Leslie Molag	The matching condition for larger size Riemann-Hilbert problems	https://arxiv.org/abs/1911.01322	FALSE	FALSE																			
2019	Johannes Nicaise and John Christian Ottem	Tropical degenerations and stable rationality	https://arxiv.org/abs/1911.06138	TRUE	TRUE	LIRIAS3555657	Nicaise, Johannes, and Ottem, John Christian. “Tropical Degenerations and Stable Rationality.” <i>Duke Mathematical Journal</i>, 2021, pp. Duke Mathematical Journal; 2021.																	
2019	Nero Budur, Javier Fern\'andez de Bobadilla, Quy Thuong L\^e et al.	Cohomology of contact loci	https://arxiv.org/abs/1911.08213	TRUE	TRUE	LIRIAS3164350	Budur, Nero, et al. “Cohomology of Contact Loci.” <i>Journal Of Differential Geometry</i>, 2020, pp. Journal Of Differential Geometry; 2020.																	
2019	Jorge Castillejos, Samuel Evington, Aaron Tikuisis et al.	Uniform property Gamma	https://arxiv.org/abs/1912.04207	FALSE	FALSE																			
2013	Martin Callies, Yael Fregier, Christopher L. Rogers, Marco Zambon	Homotopy moment maps	https://arxiv.org/abs/1304.2051	TRUE	TRUE	LIRIAS291233	Callies, Martin, et al. “Homotopy Moment Maps.” <i>Advances in Mathematics</i>, vol. 303, 2016, pp. 954–1043.																	
2014	Karel Dekimpe and Gert-Jan Dugardein	Homotopy minimal periods for hyperbolic maps on infra-nilmaifolds	https://arxiv.org/abs/1408.5279	TRUE	TRUE	LIRIAS2363083	Dekimpe, Karel, and Dugardein, Gert-Jan. “HOMOTOPY MINIMAL PERIODS FOR HYPERBOLIC MAPS ON INFRA-NILMANIFOLDS.” <i>Nagoya Mathematical Journal</i>, vol. 232, 2018, pp. 1–18.																	
2015	Karel Dekimpe, Daciberg Lima Goncalves	The R∞ property for nilpotent quotients of surface groups	https://arxiv.org/abs/1505.07974	TRUE	TRUE	LIRIAS1769324	Dekimpe, Karel, and Goncalves, Daciberg L. “The R-Infinity Property for Nilpotent Quotients of Surface Groups.” <i>Transactions of the London Mathematical Society</i>, vol. 3, no. 1, 2016, pp. 28–45.																	
2015	Nero Budur, Botong Wang	Recent results on cohomology jump loci	https://arxiv.org/abs/1507.06714	TRUE	TRUE	LIRIAS1742946	Budur, Nero, and Wang, Botong. “Recent Results on Cohomology Jump Loci.” <i>Hodge Theory and L2-Analysis</i>, vol. 39, 2017, pp. 205–241.																
2015	Nero Budur, Botong Wang	Local systems on analytic germ complements	https://arxiv.org/abs/1508.07867	TRUE	TRUE	LIRIAS242643	Budur, Nero, and Wang, Botong. “Local Systems on Analytic Germ Complements.” <i>Advances in Mathematics</i>, vol. 306, 2017, pp. 905–928.																	
2015	Sorin Popa, Dimitri Shlyakhtenko and Stefaan Vaes	Cohomology and $L^2$-Betti numbers for subfactors and quasi-regular  inclusions	https://arxiv.org/abs/1511.07329	TRUE	TRUE	LIRIAS1743352	Popa, Sorin, et al. “Cohomology and L^2-Betti Numbers for Subfactors and Quasi-Regular Inclusions.” <i>International Mathematics Research Notices</i>, vol. 2018, no. 8, 2018, pp. 2241–2331.																	
2015	Nero Budur, Yongqiang Liu, Luis Saumell et al.	Cohomology support loci of local systems	https://arxiv.org/abs/1511.08013	TRUE	TRUE	LIRIAS242659	Budur, Nero, et al. “Cohomology Support Loci of Local Systems.” <i>The Michigan Mathematical Journal</i>, vol. 66, no. 2, 2017, pp. 295–307.																	
2015	R\'emi Boutonnet, Cyril Houdayer and Stefaan Vaes	Strong solidity of free Araki-Woods factors	https://arxiv.org/abs/1512.04820	TRUE	TRUE	LIRIAS1743362	Boutonnet, Rémi, et al. “Strong Solidity of Free Araki-Woods Factors.” <i>American Journal Of Mathematics</i>, vol. 140, no. 5, 2018, pp. 1231–1252.																	
2015	Anna Sofie Krogager and Stefaan Vaes	A class of II_1 factors with exactly two group measure space  decompositions	https://arxiv.org/abs/1512.06677	TRUE	TRUE	LIRIAS1180876	Krogager, Anna, and Vaes, Stefaan. “A Class of II_1 Factors with Exactly Two Group Measure Space Decompositions.” <i>Journal De Mathématiques Pures Et Appliquées</i>, vol. 108, no. 1, 2017, pp. 88–110.																	
2016	Yuki Arano and Stefaan Vaes	C*-tensor categories and subfactors for totally disconnected groups	https://arxiv.org/abs/1602.03047	TRUE	TRUE	LIRIAS1581645	Arano, Yuki, and Vaes, Stefaan. “C*-Tensor Categories and Subfactors for Totally Disconnected Groups.” <i>Operator Algebras and Applications. The Abel Symposium 2015.</i>, vol. 12, 2016, pp. 1–43.																	
2016	Karel Dekimpe	A Users' Guide to Infra-nilmanifolds and Almost-Bieberbach groups	https://arxiv.org/abs/1603.07654	TRUE	TRUE	NONE	Karel Dekimpe. A Users' Guide to Infra-nilmanifolds and Almost-Bieberbach groups. <i>In Handbook of Group Actions</i>, vol III, eds. Ji, Lizhen & Papadopoulos, Athanase & Yau, Shing-Tung. Advanced Lectures in Mathematics 40, 215–262.																	
2016	Johannes Nicaise, Sam Payne, Franziska Schroeter	Tropical refined curve counting via motivic integration	https://arxiv.org/abs/1603.08424	TRUE	TRUE	LIRIAS2242046	Nicaise, Johannes, et al. “Tropical Refined Curve Counting via Motivic Integration.” <i>Geometry &amp; Topology</i>, vol. 22, no. 6, 2018, pp. 3175–3234.																	
2016	Johannes Nicaise	Geometric invariants for non-archimedean semialgebraic sets	https://arxiv.org/abs/1603.08732	TRUE	TRUE	LIRIAS2355320	Nicaise, Johannes. “Geometric Invariants for Non-Archimedean Semialgebraic Sets.” <i>Algebraic Geometry: Salt Lake City 2015, Part 2</i>, vol. 97, 2018, pp. 389–404.																	
2016	Tobe Deprez and Stefaan Vaes	Inner amenability, property Gamma, McDuff II_1 factors and stable  equivalence relations	https://arxiv.org/abs/1604.02911	TRUE	TRUE	LIRIAS1581643	Deprez, Tobe, and Vaes, Stefaan. “Inner Amenability, Property Gamma, McDuff II_1 Factors and Stable Equivalence Relations.” <i>Ergodic Theory and Dynamical Systems</i>, vol. 38, no. 7, 2018, pp. 2618–2624.																	
2016	Arno B.J. Kuijlaars	A Vector Equilibrium Problem for Muttalib-Borodin Biorthogonal Ensembles	https://arxiv.org/abs/1604.03070	TRUE	TRUE	LIRIAS1643668	Kuijlaars, Arno. “A Vector Equilibrium Problem for Muttalib-Borodin Biorthogonal Ensembles.” <i>Symmetry, Integrability and Geometry: Methods and Applications</i>, vol. 12, 2016, pp. 1–15.																	
2016	Cyril Houdayer, Dimitri Shlyakhtenko, Stefaan Vaes	Classification of a family of non almost periodic free Araki-Woods  factors	https://arxiv.org/abs/1605.06057	TRUE	TRUE	LIRIAS1743360	Houdayer, Cyril, et al. “Classification of a Family of Non Almost Periodic Free Araki-Woods Factors.” <i>Journal of the European Mathematical Society</i>, vol. 21, no. 10, 2019, pp. 3113–3142.																	
2016	Iakovos Androulidakis and Marco Zambon	Almost regular Poisson manifolds and their holonomy groupoids	https://arxiv.org/abs/1606.09269	TRUE	TRUE	LIRIAS291215	Androulidakis, Iakovos, and Zambon, Marco. “Almost Regular Poisson Manifolds and Their Holonomy Groupoids.” <i>Selecta Mathematica</i>, vol. 23, no. 3, 2017, pp. 2291–2330.																	
2016	Tom Claeys, Arno B.J. Kuijlaars, Karl Liechty et al.	Propagation of singular behavior for Gaussian perturbations of random  matrices	https://arxiv.org/abs/1608.05870	TRUE	TRUE	LIRIAS1995777	Claeys, Tom, et al. “Propagation of Singular Behavior for Gaussian Perturbations of Random Matrices.” <i>Communications In Mathematical Physics</i>, vol. 362, no. 1, 2018, pp. 1–54.																	
2016	Jean-Baptiste Teyssier	Skeletons and moduli of Stokes torsors	https://arxiv.org/abs/1609.01559	TRUE	TRUE	LIRIAS1742977	Teyssier, Jean-baptiste. “Skeleton and Moduli of Stokes Torsors.” <i>Annales Scientifiques De L'École Normale Supérieure</i>, vol. 52, no. 2, 2019, pp. 337–365.																	
2016	Nero Budur, Yongqiang Liu, Botong Wang	The monodromy theorem for compact K\"ahler manifolds and smooth  quasi-projective varieties	https://arxiv.org/abs/1609.06478	TRUE	TRUE	LIRIAS242661	Budur, Nero, et al. “The Monodromy Theorem for Compact Kaehler Manifolds and Smooth Quasi-Projective Varieties.” <i>Mathematische Annalen</i>, vol. 371, no. 3-4, 2017, pp. 1069–1086.																	
2016	Emmanuel Bultot and Johannes Nicaise	Computing motivic zeta functions on log smooth models	https://arxiv.org/abs/1610.00742	TRUE	TRUE	LIRIAS3057155	Bultot, Emmanuel, and Nicaise, Johannes. “Computing Motivic Zeta Functions on Log Smooth Models.” <i>Mathematische Zeitschrift</i>, vol. 295, no. 1-2, 2020, pp. 427–462.																	
2016	Leonid Ryvkin, Tilmann Wurzbacher and Marco Zambon	Conserved quantities on multisymplectic manifolds	https://arxiv.org/abs/1610.05592	TRUE	TRUE	LIRIAS1742759	Ryvkin, Leonid, et al. <i>CONSERVED QUANTITIES ON MULTISYMPLECTIC MANIFOLDS</i>. Vol. 108, Cambridge University Press (CUP), 2020.																	
2016	Anna Sofie Krogager and Stefaan Vaes	Thin II_1 factors with no Cartan subalgebras	https://arxiv.org/abs/1611.02138	TRUE	TRUE	NONE	Anna Sofie Krogager and Stefaan Vaes, Thin II1 factors with no Cartan subalgebras. Kyoto <i>Journal of Mathematics</i> 59, 815-867.																	
2016	Arno B.J. Kuijlaars and Pablo Rom\'an	Spherical functions approach to sums of random Hermitian matrices	https://arxiv.org/abs/1611.08932	TRUE	TRUE	LIRIAS1743367	Kuijlaars, Arno, and Roman, Pablo Manuel. “Spherical Functions Approach to Sums of Random Hermitian Matrices.” <i>International Mathematics Research Notices</i>, vol. 2019, no. 4, 2017, pp. 1005–1029.																	
2017	David Kyed, Sven Raum, Stefaan Vaes and Matthias Valvekens	L2-Betti numbers of rigid C*-tensor categories and discrete quantum groups	https://arxiv.org/abs/1701.06447	TRUE	TRUE	LIRIAS57261	Kyed, David, et al. “L^2-Betti Numbers of Rigid C*-Tensor Categories and Discrete Quantum Groups.” <i>Analysis &amp; PDE</i>, vol. 10, no. 7, 2017, pp. 1757–1791.																	
2017	Lars Halvard Halle and Johannes Nicaise	Motivic zeta functions of degenerating Calabi-Yau varieties	https://arxiv.org/abs/1701.09155	TRUE	TRUE	LIRIAS1582830	Halle, Lars Halvard, and Nicaise, Johannes. “Motivic Zeta Functions of Degenerating Calabi-Yau Varieties.” <i>Mathematische Annalen</i>, vol. 370, no. 3-4, 2018, pp. 1277–1320.																	
2017	Nero Budur, Botong Wang	Cohomology jump loci of quasi-compact K\"ahler manifolds	https://arxiv.org/abs/1702.02186	TRUE	TRUE	LIRIAS3021535	Budur, Nero, and Wang, Botong. “Cohomology Jump Loci of Quasi-Compact Kähler Manifolds.” <i>Pure And Applied Mathematics Quarterly</i>, vol. 16, no. 4, 2020, pp. 981–999.																	
2017	Nero Budur, Botong Wang	Absolute sets and the Decomposition Theorem	https://arxiv.org/abs/1702.06267	TRUE	TRUE	LIRIAS1995412	Budur, Nero, and Wang, Botong. “Absolute Sets and the Decomposition Theorem.” <i>Annales Scientifiques De L Ecole Normale Superieure</i>, vol. 53, no. 2, 2020, pp. 469–536.																	
2017	Florian Schaetz and Marco Zambon	Deformations of pre-symplectic structures and the Koszul  $L_\infty$-algebra	https://arxiv.org/abs/1703.00290	TRUE	TRUE	LIRIAS1742738	Schatz, Florian, and Zambon, Marco. <i>Deformations of Pre-Symplectic Structures and the Koszul L-Infinity-Algebra</i>. Vol. 2020, Oxford University Press, 2020.																	
2017	Arno Kuijlaars and Erwin Miña-Díaz	Universality for conditional measures of the sine point process	https://arxiv.org/abs/1703.02349	TRUE	TRUE	LIRIAS2820397	Kuijlaars, Arno BJ, and Mina-Diaz, Erwin. “Universality for Conditional Measures of the Sine Point Process.” <i>Journal Of Approximation Theory</i>, vol. 243, 2019, pp. 1–24.																	
2017	Arnaud Brothier, Tobe Deprez and Stefaan Vaes	Rigidity for von Neumann algebras given by locally compact groups and their crossed products	https://arxiv.org/abs/1703.09092	TRUE	TRUE	LIRIAS1628525	Brothier, Arnaud, et al. “Rigidity for Von Neumann Algebras given by Locally Compact Groups and Their Crossed Products.” <i>Communications in Mathematical Physics</i>, vol. 361, no. 1, 2018, pp. 81–125.																	
2017	Johannes Nicaise and Sam Payne	A tropical motivic Fubini theorem with applications to Donaldson-Thomas  theory	https://arxiv.org/abs/1703.10228	TRUE	TRUE	LIRIAS2354130	Nicaise, Johannes, and Payne, Sam. “A Tropical Motivic Fubini Theorem with Applications to Donaldson-Thomas Theory.” <i>Duke Mathematical Journal</i>, vol. 168, no. 10, 2019, pp. 1843–1886.																	
2017	Dietrich Burde, Karel Dekimpe and Bert Verbeke	Almost inner derivations of Lie algebras	https://arxiv.org/abs/1704.06159	TRUE	TRUE																			
2017	Stefaan Vaes and Jonas Wahl	Bernoulli actions of type III1 and L2-cohomology	https://arxiv.org/abs/1705.00439	TRUE	TRUE	LIRIAS1628281	Vaes, Stefaan, and Wahl, Jonas. “Bernoulli Actions of Type III_1 and L^2-Cohomology.” <i>Geometric and Functional Analysis Gafa</i>, vol. 28, no. 2, 2018, pp. 518–562.																	
2017	Nero Budur, Michele Zordan	On representation zeta functions for special linear groups	https://arxiv.org/abs/1706.05525	TRUE	TRUE	LIRIAS1742976	Budur, Nero, and Zordan, Michele. “On Representation Zeta Functions for Special Linear Groups.” <i>International Mathematics Research Notices</i>, vol. 2020, no. 3, 2020, pp. 868–882.																	
2017	Johannes Nicaise and Evgeny Shinder	The motivic nearby fiber and degeneration of stable rationality	https://arxiv.org/abs/1708.02790	TRUE	TRUE	LIRIAS2377493	Nicaise, Johannes, and Shinder, Evgeny. “The Motivic Nearby Fiber and Degeneration of Stable Rationality.” <i>Inventiones Mathematicae</i>, vol. 271, no. 2, 2019, pp. 377–413.																	
2017	Niels Bonneux, Arno B.J. Kuijlaars	Exceptional Laguerre polynomials	https://arxiv.org/abs/1708.03106	TRUE	TRUE	LIRIAS1743368	Bonneux, Niels, and Kuijlaars, Arno. “Exceptional Laguerre Polynomials.” <i>Studies in Applied Mathematics</i>, vol. 141, no. 4, 2018, pp. 547–595.																	
2017	Olivier Brahic and Marco Zambon	L∞-actions of Lie algebroids	https://arxiv.org/abs/1708.06415	TRUE	FALSE																			
2017	Román Sasyk, Asger Törnquist and Stefaan Vaes	Non-classification of free Araki-Woods factors and τ-invariants	https://arxiv.org/abs/1708.07496	TRUE	TRUE	LIRIAS1743366	Sasyk, Roman, et al. “Non-Classification of Free Araki-Woods Factors and Tau-Invariants.” <i>Groups, Geometry and Dynamics</i>, vol. 13, no. 4, 2019, pp. 1219–1234.																	
2017	Johannes Nicaise	Igusa zeta functions and the non-archimedean SYZ fibration	https://arxiv.org/abs/1708.09637	TRUE	TRUE	LIRIAS1624367	Nicaise, Johannes. “Igusa Zeta Functions and the Non-Archimedean SYZ Fibration.” <i>Acta Mathematica Vietnamica</i>, vol. 43, no. 1, 2018, pp. 31–44.																	
2017	Nero Budur, Pietro Gatti, Yongqiang Liu et al.	On the length of perverse sheaves and D-modules	https://arxiv.org/abs/1709.00876	TRUE	TRUE	LIRIAS2378969	Budur, Nero, et al. “On the Length of Perverse Sheaves and D-Modules.” <i>Bulletin Mathematique De La Societe Des Sciences Mathematiques De Roumanie</i>, vol. 109, no. 4, 2019, pp. 355–369.																	
2017	Karel Dekimpe, Tom Kaiser, Sam Tertooy	The Reidemeister spectra of low dimensional crystallographic groups	https://arxiv.org/abs/1709.07649	TRUE	TRUE	LIRIAS2812576	Dekimpe, Karel, et al. “The Reidemeister Spectra of Low Dimensional Crystallographic Groups.” <i>Journal Of Algebra</i>, vol. 533, 2019, pp. 353–375.																	
2017	Karel Dekimpe, Sam Tertooy and Iris Van den Bussche	Reidemeister zeta functions of low-dimensional almost-crystallographic  groups are rational	https://arxiv.org/abs/1710.02335	TRUE	TRUE	LIRIAS1642996	Dekimpe, Karel, et al. “Reidemeister Zeta Functions of Low-Dimensional Almost-Crystallographic Groups Are Rational.” <i>Communications In Algebra</i>, vol. 46, no. 9, 2018, pp. 4090–4103.																	
2017	Karel Dekimpe and Sam Tertooy and Antonio R. Vargas	Fixed points of diffeomorphisms on nilmanifolds with a free nilpotent  fundamental group	https://arxiv.org/abs/1710.09662	TRUE	TRUE	LIRIAS3148417	Dekimpe, Karel, et al. “Fixed Points of Diffeomorphisms on Nilmanifolds with a Free Nilpotent Fundamental Group.” <i>The Asian Journal of Mathematics</i>, vol. 24, no. 1, 2020, pp. 147–164.																	
2017	D. Burde, W. A. Moens, K. Dekimpe	Commutative post-Lie algebra structures and linear equations for  nilpotent Lie algebras	https://arxiv.org/abs/1711.01964	TRUE	TRUE	NONE	Dietrich Burde, Karel Dekimpe and Wolfgang Moens, Commutative post-Lie algebra structures and linear equations for nilpotent Lie algebras. <i>Journal of Algebra 526</i>, 12-29.																	
2017	Robert F. Brown, Charlotte Deconinck, Karel Dekimpe et al.	Lifting classes for the fixed point theory of $n$-valued maps	https://arxiv.org/abs/1711.02722	TRUE	TRUE	LIRIAS3029789	Brown, Robert F, et al. “Lifting Classes for the Fixed Point Theory of n-Valued Maps.” <i>Topology And Its Applications</i>, vol. 274, 2020, pp. Topology And Its Applications; 2020; Vol. 274; pp.																	
2017	Karel Dekimpe, Sam Tertooy and Iris Van den Bussche	Reidemeister spectra for solvmanifolds in low dimensions	https://arxiv.org/abs/1711.08650	TRUE	TRUE	LIRIAS2820378	Dekimpe, Karel, et al. “REIDEMEISTER SPECTRA FOR SOLVMANIFOLDS IN LOW DIMENSIONS.” <i>Topological Methods In Nonlinear Analysis</i>, vol. 53, no. 2, 2019, pp. 575–601.																	
2017	Maurice Duits and Arno B.J. Kuijlaars	The two periodic Aztec diamond and matrix valued orthogonal polynomials	https://arxiv.org/abs/1712.05636	TRUE	TRUE	LIRIAS3433131	Duits, Maurice, and Kuijlaars, Arno BJ. “The Two-Periodic Aztec Diamond and Matrix Valued Orthogonal Polynomials.” <i>Journal Of The European Mathematical Society</i>, vol. 23, no. 4, 2021, pp. 1029–1131.																	
2018	Marius Crainic and Ori Yudilevich	Lie Pseudogroups \`a la Cartan	https://arxiv.org/abs/1801.00370	FALSE	FALSE																			
2018	Johannes Nicaise, Chenyang Xu, and Tony Yue Yu	The non-archimedean SYZ fibration	https://arxiv.org/abs/1802.00287	TRUE	TRUE	LIRIAS2353302	Nicaise, Johannes, et al. “The Non-Archimedean SYZ Fibration.” <i>Compositio Mathematica</i>, vol. 155, no. 5, 2019, pp. 953-972.																	
2018	Jean-Baptiste Teyssier	Moduli of Stokes torsors and singularities of differential equations	https://arxiv.org/abs/1802.00289	TRUE	TRUE	NONE	Jean-Baptiste Teyssier, Skeletons and moduli of Stokes torsors.<i> Annales Scientifiques de l'École Normale Supérieure</i> 52, 337-365.																	
2018	Alfonso Garmendia and Marco Zambon	Hausdorff Morita Equivalence of singular foliations	https://arxiv.org/abs/1803.00896	TRUE	TRUE	LIRIAS1993506	Garmendia, Alfonso, and Zambon, Marco. “Hausdorff Morita Equivalence of Singular Foliations.” <i>Annals Of Global Analysis And Geometry</i>, vol. 55, no. 1, 2019, pp. 99–132.																	
2018	Nero Budur and Ziyu Zhang	Formality conjecture for K3 surfaces	https://arxiv.org/abs/1803.03974	TRUE	TRUE	LIRIAS2369314	Budur, Nero, and Zhang, Ziyu. “Formality Conjecture for K3 Surfaces.” <i>Compositio Mathematica</i>, vol. 155, no. 5, 2019, p. 902.																	
2018	Stefaan Vaes and Matthias Valvekens	Property (T) discrete quantum groups and subfactors with triangle presentations	https://arxiv.org/abs/1804.04006	TRUE	TRUE	LIRIAS2412317	Vaes, Stefaan, and Valvekens, Matthias. “Property (T) Discrete Quantum Groups and Subfactors with Triangle Presentations.” <i>Advances In Mathematics</i>, vol. 345, 2019, pp. 382–428.																	
2018	Alfonso Garmendia and Ori Yudilevich	On the Inner Automorphisms of a Singular Foliation	https://arxiv.org/abs/1804.06103	TRUE	TRUE	LIRIAS2861971	Garmendia, Alfonso, and Yudilevich, Ori. “On the Inner Automorphisms of a Singular Foliation.” <i>Mathematische Zeitschrift</i>, vol. 293, no. 1-2, 2019, pp. 725–729.																	
2018	Nero Budur and Marcel Rubi\'o	L-infinity pairs and applications to singularities	https://arxiv.org/abs/1804.06672	TRUE	TRUE	LIRIAS2823765	Budur, Nero, and Rubió, Marcel. “L-Infinity Pairs and Applications to Singularities.” <i>Advances In Mathematics</i>, vol. 354, 2019, pp. 1–40.																	
2018	Marco Zambon, Iakovos Androulidakis	Singular subalgebroids	https://arxiv.org/abs/1805.02480	TRUE	TRUE	LIRIAS911718	Androulidakis, Iakovos, and Zambon, Marco. “Stefan-Sussmann Singular Foliations, Singular Subalgebroids,and Their Associated Sheaves.” <i>International Journal of Geometric Methods in Modern Physics</i>, vol. 13, no. Supp. 1, 2016, pp. International Journal of Geometric Methods in Modern Physics; 2016; Vol. 13; iss. Supp. 1; pp.																	
2018	Daan Huybrechs and Arno B. J. Kuijlaars and Nele Lejon	A numerical method for oscillatory integrals with coalescing saddle  points	https://arxiv.org/abs/1806.06549	TRUE	TRUE	NONE	Daan Huybrechs, Arno Kuijlaars and Nele Lejon, A numerical method for oscillatory integrals with coalescing saddle points,<i> SIAM Journal on Numerical Analysis </i>57, 2707-2729.																	
2018	Florian Sch\"atz, Marco Zambon	Deformations of Pre-Symplectic Structures: a Dirac Geometry Approach	https://arxiv.org/abs/1807.10148	TRUE	TRUE	LIRIAS2473708	Schatz, Florian, and Zambon, Marco. “Deformations of Pre-Symplectic Structures: a Dirac Geometry Approach.” <i>Symmetry Integrability And Geometry-Methods And Applications</i>, vol. 14, 2018, pp. Symmetry Integrability And Geometry-Methods And Applications; 2018; Vol. 14; pp.																	
2018	Fu Liu, Brian Osserman, Montserrat Teixidor i Bigas and Naizhen Zhang	The strong maximal rank conjecture and moduli spaces of curves	https://arxiv.org/abs/1808.01290	FALSE	FALSE																			
2018	Przemys{\l}aw Ohrysko, Mateusz Wasilewski	Inversion problem in measure and Fourier-Stieltjes algebras	https://arxiv.org/abs/1808.06225	TRUE	TRUE	LIRIAS2893784	Ohrysko, Przemysław, and Wasilewski, Mateusz. “Inversion Problem in Measure and Fourier-Stieltjes Algebras.” <i>Journal Of Functional Analysis</i>, vol. 278, no. 5, 2020, pp. 1–19.																	
2018	K. Dekimpe and D. L. Gon\c{c}alves	The $R_\infty$--property for nilpotent quotients of Baumslag--Solitar  groups	https://arxiv.org/abs/1809.02947	TRUE	TRUE	LIRIAS3039972	Dekimpe, Karel, and Goncalves, Daciberg Lima. “The R-Infinity-Property for Nilpotent Quotients of Baumslag-Solitar Groups.” <i>Journal Of Group Theory</i>, vol. 23, no. 3, 2020, pp. 545–562.																	
2018	Nero Budur	Rational singularities, quiver moment maps, and representations of  surface groups	https://arxiv.org/abs/1809.05180	TRUE	TRUE	LIRIAS2833444	Budur, Nero. “Rational Singularities, Quiver Moment Maps, and Representations of Surface Groups.” <i>International Mathematics Research Notices</i>, 2019, pp. International Mathematics Research Notices; 2019.																	
2018	A. B. J. Kuijlaars, L. D. Molag	The local universality of Muttalib-Borodin biorthogonal ensembles with parameter θ = 1/2	https://arxiv.org/abs/1810.00741	TRUE	TRUE	LIRIAS2826562	Kuijlaars, ABJ, and Molag, LD. “The Local Universality of Muttalib-Borodin Biorthogonal Ensembles with Parameter Theta=1/2.” <i>Nonlinearity</i>, vol. 32, no. 8, 2019, pp. 3023–3081.																	
2018	Jorge Castillejos	C*-algebras and their nuclear dimension	https://arxiv.org/abs/1810.04235	TRUE	FALSE																			
2018	Florian Schaetz, Marco Zambon	Gauge equivalences for foliations and pre-symplectic structures	https://arxiv.org/abs/1810.04555	TRUE	TRUE	LIRIAS3353178	Schaetz, Florian, and Zambon, Marco. “Gauge Equivalences for Foliations and Pre-Symplectic Structures.” <i>Communications In Contemporary Mathematics</i>, vol. 23, no. 07, 2021, pp. Communications In Contemporary Mathematics; 2021; Vol. 23; iss. 07; pp.																	
2018	Haoyu Hu and Jean-Baptiste Teyssier	Characteristic cycle and wild ramification for nearby cycles of étale sheaves.	https://arxiv.org/abs/1811.02860	FALSE	FALSE																			
2018	Sorin Popa, Dimitri Shlyakhtenko and Stefaan Vaes	Classification of regular subalgebras of the hyperfinite II1 factor	https://arxiv.org/abs/1811.06929	TRUE	TRUE	LIRIAS3145287	Popa, Sorin, et al. “Classification of Regular Subalgebras of the Hyperfinite II(1 )Factor.” <i>Journal De Mathematiques Pures Et Appliquees</i>, vol. 140, 2020, pp. 280–308.																	
2018	Michael Brannan, Alexandru Chirvasitu, Kari Eifler, Samuel Harris, Vern Paulsen, Xiaoyu Su and Mateusz Wasilewski	Bigalois extensions and the graph isomorphism game	https://arxiv.org/abs/1812.11474	TRUE	TRUE	LIRIAS3039878	Brannan, Michael, et al. “Bigalois Extensions and the Graph Isomorphism Game.” <i>Communications In Mathematical Physics</i>, vol. 375, no. 3, 2020, pp. 1777–1809.																	
2010	David Bourqui, Johannes Nicaise and Julien Sebag	Arc Schemes in Geometry and Differential Algebra		TRUE	TRUE	LIRIAS62192	Nicaise, Johannes, and Sebag, Julien. “Greenberg Approximation and the Geometry of Arc Spaces.” <i>Communications in Algebra</i>, vol. 38, no. 11, 2010, pp. 4077–4096.																	
2016	Antonio Vargas	The Saff-Varga width conjecture and entire functions		TRUE	TRUE	LIRIAS1927639	Vargas, Antonio. <i>Scaling Limits for Partial Sums of Power Series</i>. 2016.																	
2018	Antoine Chambert-Loir, Johannes Nicaise and Julien Sebag	Motivic integration		TRUE	TRUE	LIRIAS1742895	Chambert-Loir, Antoine, et al. <i>Motivic Integration</i>. Vol. 325, Birkhaüser; Basel, 2018.																	
`;

export default function load(db) {
  if (window.localStorage.imported) return;
  const parsed = items
    .split("\n")
    .filter((item) => !!item.trim())
    .map((item) => {
      const [
        year,
        authors,
        title,
        arxiv_url,
        acceptedStr,
        publishedStr,
        lirias_id,
        citation,
      ] = item.split("\t");
      const accepted = acceptedStr == "TRUE";
      const published = publishedStr == "TRUE";

      const docRef = addDoc(collection(db, "papers"), {
        authors: authors || "",
        title: title || "",
        arxiv_url: arxiv_url || "",
        accepted,
        published,
        lirias_id: lirias_id || "",
        citation: citation || "",
      });
    });

  window.localStorage.imported = true;
}

import logo from "./logo.svg";
import "./App.css";
import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import parseHTML from "html-react-parser";
import { downloadAsFile } from "./utils.js";

import {
  getFirestore,
  collection,
  addDoc,
  updateDoc,
  doc,
  query,
  orderBy,
} from "firebase/firestore";

import {
  useCollection,
  useCollectionData,
} from "react-firebase-hooks/firestore";
// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";

import load from "./import";

const firebaseConfig = {
  apiKey: "AIzaSyDqvREDkW750bqQUcYP7pe__rM80XSiE-U",
  authDomain: "methusalem-papers.firebaseapp.com",
  projectId: "methusalem-papers",
  storageBucket: "methusalem-papers.appspot.com",
  messagingSenderId: "387504051462",
  appId: "1:387504051462:web:2f7c9662e4441f6b1ffa87",
};

const app = initializeApp(firebaseConfig);
console.log("APP", app);
const db = getFirestore(app);

// load(db);
//

function Main() {
  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<App />} />
        <Route path="/html" element={<GenerateHTML />} />
      </Routes>
    </BrowserRouter>
  );
}

const byArXivYear = (a, b) =>
  a.data().arxiv_url < b.data().arxiv_url
    ? -1
    : a.data().arxiv_url > b.data().arxiv_url
    ? 1
    : 0;

const byKey = (f) => (a, b) => f(a) > f(b) ? 1 : f(a) < f(b) ? -1 : 0;


const groupByPublishedYear = (docs) => {
  const byYear = {
    "no citation": [],
    "unknown year": [],
  };
  docs.forEach((doc) => {
    const citation = doc.data().citation;
    if (!citation) return byYear["no citation"].push(doc);
    const year = citation.match(/(?<!(Symposium|City) )20\d\d/);
    if (!year) return byYear["unknown year"].push(doc);
    byYear[year[0]] = [...(byYear[year[0]] || []), doc];
  });
  return Object.entries(byYear);
};


function GenerateHTML() {
  const [value, loading, error] = useCollection(
    query(collection(db, "papers"), orderBy("arxiv_url", "desc")),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  if (error) return <strong>Error: {JSON.stringify(error)}</strong>;
  if (loading) return <span>Collection: Loading...</span>;
  if (!value) return <span>Loading value</span>;

  const preprints = value.docs.filter(
    (doc) => doc.data().published == false && doc.data().accepted == false
  );

  const accepted = value.docs.filter(
    (doc) => doc.data().published == false && doc.data().accepted == true
  );

  const published = value.docs.filter((doc) => doc.data().published == true);

  const endDotIfNeeded = (str) => (str.endsWith(".") ? str : str + ".");

  const CopyButton = ({id}) => {
    return <button onClick ={() => {
      navigator.clipboard.writeText('<! --- Automaticaly generated from https://methusalem.castel.dev/html ---> \n\n' + 
        document.getElementById(id).outerHTML)
    }}>Copy</button>
  }

  const format = ({arxiv_url, authors, title, journal, citation}) => {
    const lastBit = citation => {
      const [_, _2, last] = citation.split(/(<\/i>|<\/em>)/)
      if (!last) return ('error '  +citation)
      return last.trim()
        .replace(/^,/, '')
        .replace(/,$/, '').trim()
        .replace(/(\d)-(\d)/, '$1–$2')
    }

    const cleanupLatex = text => {
      return text
        .replace(/\\'a/g, 'á')
        .replace(/\\'S/g, 'Ś')
        .replace(/\\`a/g, 'à')
        .replace(/\\"a/g, 'ä')
        .replace(/\\'o/g, 'ó')
        .replace(/\\c{c}/g, 'ç')
        .replace(/\{?\\l\}?/g, 'ł')
        .replace(/\\\^e/g, 'ê')
        .replace(/\$/g, '')
        .replace(/\\theta/g, 'θ')
        .replace(/_\\infty/g, '∞')

    }

    return [
      cleanupLatex(authors),
      `“${cleanupLatex(title)}”`,
      journal ? <i>{journal}</i> : null,
      citation ? lastBit(citation) : null,
      arxiv_url ? (
        <a href={arxiv_url} target='_blank'>
          <sup>[arXiv]</sup>
        </a>
      ) : null
    ].filter(Boolean).flatMap((e, i, a) => {
      return i >= a.length - 2 ? [e, ' '] : [e, ', ']
    })
  }
  return (
    <div className="wrapper">
      <h2>Preprints <CopyButton id='preprints' /></h2>
      <ul id='preprints'>
        {preprints
          .sort(byArXivYear)
          .sort(byKey((d) => d.data().authors))
          .map((doc) => {
            const { arxiv_url, authors, title } = doc.data();
            return (
              <li key={doc.id}>{format({authors, title, arxiv_url})}</li>
            );
          })}
      </ul>
      <h2>Accepted <CopyButton id='accepted' /></h2>
      <ul id='accepted'>
        {accepted
          .sort(byArXivYear)
          .sort(byKey((d) => d.data().authors))
          .map((doc) => {
            const { arxiv_url, authors, title, journal } = doc.data();
            return (
              <li key={doc.id}>
                {format({authors, journal, title, arxiv_url})}
              </li>
            );
          })}
      </ul>
      <h2>Published</h2>
      {/* TODO do not sort by arxiv year! */}
      {groupByPublishedYear(published).map(([year, docs]) => {
        return (
          <>
            <h3>Published in {year} <CopyButton id={`y${year}`} /></h3>
            <ul id={`y${year}`}>
              {docs
                  .sort(byKey((d) => d.data().title))
                  .sort(byKey((d) => d.data().authors)).map((d) => {
                const { arxiv_url, authors, title, journal, citation } =
                  d.data();

                if (citation && year == 'unknown year') {
                  return <li key={d.id}><span>
                        <span
                          //onClick={async () => {
                          //  const newProp = prompt(
                          //    `Citation for ${authors}, ${title}, ${journal}`,
                          //    "citation"
                          //  );
                          //  if (newProp) {
                          //    const dd = doc(db, "papers", d.id);
                          //    await updateDoc(dd, {
                          //      ...d.data(),
                          //      citation: newProp,
                          //    });
                          //  }
                          //}}
                        >
                          no citation
                        </span>{" "}
                        ({authors}, {title}, {journal})
                      </span>
                    </li>
                }

                return (
                  <li 
                    //onClick={async () => {
                    //  const newProp = prompt(
                    //    `Citation for ${authors}, ${title}, ${journal}`,
                    //    citation
                    //  );
                    //  if (newProp) {
                    //    const dd = doc(db, "papers", d.id);
                    //    await updateDoc(dd, {
                    //      ...d.data(),
                    //      citation: newProp,
                    //    });
                    //  }
                    //}}
                  key={d.id}>{format({citation, authors, title, journal, arxiv_url})}</li>
                );
              })}
            </ul>
          </>
        );
      })}
    </div>
  );
}

// TODO: fix permissions
function App() {
  const [value, loading, error] = useCollection(
    query(collection(db, "papers"), orderBy("arxiv_url", "desc")),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  window.downloadDB = async () => {
    const docs = value.docs.map((doc) => doc.data());
    const date = new Date();
    const y = date.getFullYear();
    const m = date.getMonth() + 1;
    const d = date.getDate();
    const h = date.getHours();
    const min = date.getMinutes();
    const sec = date.getSeconds();
    const pad = (n) => (n < 10 ? "0" + n : n);
    downloadAsFile(
      `methusalem-database_${y}-${pad(m)}-${pad(d)}_${pad(h)}-${pad(min)}-${pad(
        sec
      )}.json`,
      JSON.stringify(docs, null, 2)
    );
  };

  const urlParams = new URLSearchParams(window.location.search);
  const author = urlParams.get("author") || "";
  const [search, setSearch] = useState(author);

  const Header = () => (
    <tr className="head">
      <td className="no-wrap">Preprint Year</td>
      <td>Title and Authors</td>
      <td>Accepted?</td>
      <td>Published?</td>
    </tr>
  );

  const filter = (doc) =>
    doc.data().authors.toLowerCase().includes(search.toLowerCase());
  const filtered = value && value.docs.filter(filter);
  const published = value && filtered.filter((doc) => doc.data().published);
  const notpublished = value && filtered.filter((doc) => !doc.data().published);

  return (
    <div className="App">
      <h1>
        Listing papers from the Methusalem project from{" "}
        <input
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder="Search by author"
        />
      </h1>

      <div className="wrapper">
        {error && <strong>Error: {JSON.stringify(error)}</strong>}
        {loading && <span>Collection: Loading...</span>}
        {value && (
          <table className="papers">
            <tbody>
              {notpublished.length > 0 ? (
                <>
                  <tr>
                    <td className="subheader" colSpan={4}>
                      <header>
                        1. Please update the status of the following papers if
                        necessary. Once you check "Published", the paper gets
                        moved to the list of published papers below. You can
                        also change the title, authors or journal if needed.
                      </header>
                    </td>
                  </tr>
                  <Header />
                  {filtered
                    .filter((doc) => !doc.data().published)
                    .sort(byArXivYear)
                    .map((doc) => (
                      <Document d={doc} key={doc.id} />
                    ))}
                </>
              ) : (
                <tr>
                  <td className="subheader" colSpan={4}>
                    <header>1. No unpublished papers found.</header>
                  </td>
                </tr>
              )}
              <tr>
                <td className="subheader" colSpan={4}>
                  <header>
                    2. If there is a paper missing, add it using the form below.
                  </header>
                </td>
              </tr>
              <tr className="head">
                <td />
                <td>Title and Authors</td>
                <td>Accepted?</td>
                <td>Published?</td>
              </tr>
              <AddDocument />
              {published.length > 0 ? (
                <>
                  <tr>
                    <td className="subheader" colSpan={4}>
                      <header>
                        3. Below is a list of published papers. This information
                        should be correct, but please verify this and make
                        changes accordingly.
                      </header>
                    </td>
                  </tr>
                  <Header />
                  {published.map((doc) => (
                    <Document author={search} d={doc} key={doc.id} />
                  ))}
                </>
              ) : (
                <tr>
                  <td className="subheader" colSpan={4}>
                    <header>
                      3. No published papers found. No actions necessary.
                    </header>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

function Document({ d, author }) {
  const data = d.data();

  const changeProp = (text, prop, placeholder) => {
    return async () => {
      const newProp = prompt(text, placeholder || data[prop]);
      if (newProp) {
        const dd = doc(db, "papers", d.id);
        await updateDoc(dd, {
          ...data,
          [prop]: newProp,
        });
      }
    };
  };

  const Edit = ({ text, prop, label, placeholder }) => {
    return (
      <button onClick={changeProp(text, prop, placeholder)} className="edit">
        {label.replace(/\s/g, "\xa0") || "edit"}
      </button>
    );
  };

  // let journalMaybe = data.citation.match(/<i>(.*)<\/i>/);
  // if (journalMaybe) {
  //   journalMaybe = journalMaybe[1];
  // }

  return (
    <tr>
      <td className="text-center">
        {data.arxiv_url
          ? "20" +
            data.arxiv_url.replace(/\/$/, "").split("/").pop().slice(0, 2)
          : ""}
      </td>
      <td>
        <span className="flex block">
          <div className="grow flex-col justify-center">
            <a
              href={data.arxiv_url || "javascript: void 0"}
              className="text-xl"
            >
              {data.title}
            </a>
            <div>{data.authors}</div>
            {/*<div>{data.citation || "No citation yet"}</div>*/}
            {data.accepted && (
              <div>
                {data.published ? "Published in " : "To appear in "}
                {data.journal || "an unknown journal"}
              </div>
            )}
            {/*data.lirias_id && (
              <a href={`https://lirias.kuleuven.be/${data.lirias_id}?limo=0`}>
                Lirias
              </a>
              */}
          </div>
          <div className="flex-col">
            <Edit text="Edit title" prop="title" label="Edit title" />
            <Edit text="Edit ArXiv url" prop="arxiv_url" label="Edit url" />
            <Edit text="Change authors" prop="authors" label="Edit authors" />
            {/*<button
              onClick={async () => {
                const dd = doc(db, "papers", d.id);
                await updateDoc(dd, {
                  ...data,
                  journal: journalMaybe,
                });
              }}
            >
              Set {journalMaybe}
            </button>
            <button
              onClick={async () => {
                const dd = doc(db, "papers", d.id);
                await updateDoc(dd, {
                  ...data,
                  journal: window.getSelection().toString(),
                });
              }}
            >
              Get from selection
            </button>*/}
            <Edit
              label="Edit journal"
              text="Change journal"
              prop="journal"
              placeholder={data.journal}
            />

            {/*<Edit
              label="Edit citation"
              text="Change citation"
              prop="citation"
            />*/}
          </div>
        </span>
      </td>
      <td>
        <input
          type="checkbox"
          checked={data.accepted}
          onChange={async (e) => {
            if (e.target.checked) {
              var journal = prompt("Name of the journal?", data.journal);
              if (!journal) return;
            }
            const dd = doc(db, "papers", d.id);
            await updateDoc(dd, {
              ...data,
              accepted: e.target.checked,
              ...(journal ? { journal } : {}),
            });
          }}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={data.published}
          onChange={async (e) => {
            if (e.target.checked) {
              var journal = prompt("Name of the journal?", data.journal);
              if (!journal) return;
            }
            const dd = doc(db, "papers", d.id);
            await updateDoc(dd, {
              ...data,
              published: e.target.checked,
            });
          }}
        />
      </td>
    </tr>
  );
}

function AddDocument() {
  const [authors, setAuthors] = useState("");
  const [title, setTitle] = useState("");
  const [journal, setJournal] = useState("");
  const [arxiv_url, setArxivUrl] = useState("");
  const [accepted, setAccepted] = useState(false);
  const [published, setPublished] = useState(false);

  const reset = () => {
    setAuthors("");
    setTitle("");
    setArxivUrl("");
    setAccepted(false);
    setPublished(false);
    setJournal("");
  };
  return (
    <tr className="add-document">
      <td></td>
      <td>
        <label>
          <span>Title</span>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />
        </label>
        <label>
          <span>Authors</span>

          <input
            type="text"
            value={authors}
            onChange={(e) => setAuthors(e.target.value)}
            placeholder="Authors"
          />
        </label>
        <label>
          <span>ArXiv url</span>
          <input
            type="text"
            value={arxiv_url}
            onChange={(e) => setArxivUrl(e.target.value)}
            placeholder="ArXiv url (optional)"
          />
        </label>
        <label>
          <span>Journal</span>
          <input
            type="text"
            value={journal}
            onChange={(e) => setJournal(e.target.value)}
            placeholder="Journal (optional, if accepted)"
          />
        </label>
      </td>
      <td>
        <input
          type="checkbox"
          checked={accepted}
          onChange={(e) => setAccepted(e.target.checked)}
        />
      </td>
      <td>
        <input
          type="checkbox"
          checked={published}
          onChange={(e) => setPublished(e.target.checked)}
        />
      </td>
      <td>
        <button
          onClick={async () => {
            if (title == "" && arxiv_url == "") {
              alert("At least provide ArXiv url or title");
              return;
            }
            const docRef = await addDoc(collection(db, "papers"), {
              title,
              authors,
              arxiv_url,
              accepted,
              published,
              journal,
            });

            alert("Paper added!");
            reset();
          }}
        >
          Add new paper
        </button>
      </td>
    </tr>
  );
}

export default Main;
